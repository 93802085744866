import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { Alert, Button, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BCMGuidesKRJPN: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page bcm'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>How to play on KR/JPN servers</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_kr.jpg"
            alt="How to play on KR/JPN serves"
          />
        </div>
        <div className="page-details">
          <h1>How to play on KR/JPN servers</h1>
          <h2>A guide that will help you play on the KR/JPN servers!</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The KR and JPN version of Black Clover Mobile went live on the 25th of
          May 2023. Which means that they're roughly 3 months ahead of the
          Canada server (to be released on 18.08.2023).
        </p>
        <p>
          If you want to play and try one of those versions, you have to bypass
          a pretty big hurdle. The issue is that without a KR or JPN
          Google/Apple accounts,{' '}
          <strong>
            you won't be able to download the game from the respective markets
          </strong>
          . And you have two options to resolve the issue:
        </p>
        <ul>
          <li>Download an APK from QooApp or other website on your phone,</li>
          <li>Download an emulator and install the APK there.</li>
        </ul>
        <p>
          If you plan to reroll, we suggest using{' '}
          <strong>
            the emulator as this will make the whole process a lot easier
          </strong>{' '}
          and furthermore, emulators can record macros which can further improve
          both the reroll process and the daily grind.
        </p>
        <p>
          The emulator we suggest using is <strong>BlueStacks</strong> as it's
          fast and reliable - especially when you want to setup multiple
          instances.
        </p>
        <p>
          You can download Bluestacks from our link below and by doing so
          support Prydwen!
        </p>
        <div className="banner play-on-pc bcm">
          <div className="cta">
            <h4>Play Black Clover Mobile on PC</h4>
            <OutboundLink href="https://bstk.me/uFDBDDdNm" target="_blank">
              <Button variant="primary">Play now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="How to install the game" />
        <Alert variant="primary">
          <p>
            Black Clover Mobile JPN and KR versions are{' '}
            <strong>region locked</strong>. This means if you live outside of
            those two countries, you will need to use a VPN to login to the game
            and bypass the restriction. We suggest using{' '}
            <a href="https://protonvpn.com/" target="_blank" rel="noreferrer">
              Proton VPN
            </a>{' '}
            as in its free version it gives you the Japanese gateway (it works
            both for KR and JPN clients somehow).
          </p>
          <p>
            Keep in mind that you only need to have the VPN running when you
            login to the game - once you do, you can safely disable it (at least
            that's how it works in the predownload phase, we will have to see if
            it's the same after release).
          </p>
        </Alert>
        <h5>I have an KR/JPN Google/Apple account</h5>
        <p>
          If you have a KR/JPN Google (or Apple) account, here are the official
          links to the KR/JPNs versions in the Google/Apple stores. All you need
          to do is download the game and you're done!
        </p>
        <Tabs
          defaultActiveKey="KR"
          transition={false}
          id="current-events-tabs"
          className="current-event-tabs"
        >
          <Tab eventKey="KR" title="KR">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.vic.bc.kr"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/kr/app/id6444939616"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
          <Tab eventKey="JPN" title="JPN">
            <Row>
              <Col xs="12" xl="8" xxl="6">
                <div className="list-box">
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faGoogle} />
                      Google Play
                    </div>
                    <div className="list-value">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.vic.bc.jp"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                  <div className="list-single-row">
                    <div className="list-category">
                      <FontAwesomeIcon width="18" icon={faApple} /> Apple Store
                    </div>
                    <div className="list-value">
                      <a
                        href="https://apps.apple.com/jp/app/id6444940093"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FontAwesomeIcon
                          width="18"
                          icon={faArrowUpRightFromSquare}
                        />{' '}
                        Open in new window
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Tab>
        </Tabs>
        <br />
        <h5>I don't have an KR/JPN Google/Apple account</h5>
        <p>Well, things won't be as easy in this variant.</p>
        <p>
          If you decide to play on the emulator or your phone, you will have to
          use QooApp to download the game.
        </p>
        <ul>
          <li>
            <a
              href="https://apps.qoo-app.com/en/app/22456"
              target="_blank"
              rel="noreferrer"
            >
              Black Clover Mobile KR on QooApp
            </a>
          </li>
          <li>
            <a
              href="https://apps.qoo-app.com/en/app/19340"
              target="_blank"
              rel="noreferrer"
            >
              Black Clover Mobile JPN on QooApp
            </a>
          </li>
        </ul>
        <p>
          On your phone, you just have to click the link above, download QooApp
          and then you will be able to install the game.
        </p>
        <p>
          On BlueStacks, you will have to create a new instance and then
          download the QooApp APK from{' '}
          <a
            href="https://m-apps.qoo-app.com/en-US/app/880"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <p>
          Once you have it downloaded, just move the APK file to your opened
          BlueStacks instance - this will start the installation process. Next,
          open QooApp and search for 'Black Clover Mobile' there and pick either
          the Korean or Japanese version depending on which one you want to
          play. Then install it.
        </p>
        <p>
          Here's the very important step.{' '}
          <strong className="red">
            If you plan to reroll using the multi-instance trick, don't launch
            the game.
          </strong>{' '}
          If you open it, even for a few seconds, the device ID will be saved
          somewhere in the game and if you clone the instance after, you won't
          be able to open it at the same time in another one. That's why you
          need to clone the instance first and only reroll on the cloned
          instances.
        </p>
        <p>
          Before you launch the game,{' '}
          <strong>
            make sure to open Proton VPN and connect to an endpoint in Japan
          </strong>
          . If not, whenever you will try to login as a guest (or create an
          account) the game will kick you out. Once you pass the wall and the
          game will start downloading the patch (around 1GB) you can safely
          disable the VPN. Also, most likely, you will need to use the VPN every
          time you will want to login to the game.
        </p>
        <p>Now you're ready to play, have fun!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesKRJPN;

export const Head: React.FC = () => (
  <Seo
    title="How to play on KR/JPN servers | Black Clover M | Prydwen Institute"
    description="A guide that will help you play on the KR/JPN servers!"
  />
);
